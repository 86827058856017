<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Застройщик'"
      :title="'Список Менеджеров Застройщика'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'Список Менеджеров Застройщика'"
    >
      <div class="list__buttons">
        <MainButton @click.native="handleCreate()">Create User</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="list">
        <div class="credits" v-if="developer">
          <div class="credits__title">Кредиты Застройщика</div>
          <div v-if="!isChangeCredit" class="credits__list">
            <div class="credits__list-item">
              <b>Star Date:</b> {{ developer.credits.startDate }}
            </div>

            <div class="credits__list-item"><b>End Date:</b> {{ developer.credits.endDate }}</div>
            <div class="credits__list-item"><b>Limit:</b> {{ developer.credits.limit }}</div>
            <div class="credits__list-item">
              <b>Remaining:</b> {{ developer.credits.remaining }}
            </div>
            <div class="credits__list-item"><b>Used:</b> {{ developer.credits.used }}</div>
            <div class="credits__list-item">
              <b>Auto Renewal:</b> {{ developer.credits.autoRenewal ? "Yes" : "No" }}
            </div>
            <div class="credits__list-item">
              <b>Active Balance:</b> {{ developer.credits.isActive ? "Yes" : "No" }}
            </div>
            <div class="credits__button">
              <MainButton @click.native="handleOpenChangeCredits()">Change Credits</MainButton>
            </div>
          </div>
          <div v-else class="credits__form">
            <p class="form-create__label">Limit</p>
            <div class="input-container">
              <InputText
                :set-value="formDataToSend"
                :id="'limit'"
                :label="'limit'"
                :type="'number'"
                :placeholder="'Credits Limit'"
                isBordered
              />
            </div>
            <p class="form-create__label">Used</p>
            <div class="input-container">
              <InputText
                :set-value="formDataToSend"
                :id="'used'"
                :label="'used'"
                :type="'number'"
                :placeholder="'Credits Used'"
                isBordered
              />
            </div>
            <div class="input-container">
              <MainCheckbox :label="'Active'" :id="'is_active'" :set-value="formDataToSend" />
            </div>
            <div class="input-container">
              <MainCheckbox
                :label="'Auto Renewal'"
                :id="'auto_renewal'"
                :set-value="formDataToSend"
              />
            </div>
            <div class="credits__button">
              <MainButton @click.native="handleSubmitChangeCredits()">Submit</MainButton>
            </div>
          </div>
        </div>
        <div v-if="developer" class="list__title"><b>Застройщик:</b> {{ developer.name }}</div>
        <div v-if="usersList.length">
          <CustomTable
            v-if="usersList.length"
            :data="usersList"
            :deleted-keys="tableOptions.deletedKeys"
            :rename-columns="tableOptions.renameColumn"
            :custom-order="tableOptions.customOrder"
            :is-action-block-visible="tableOptions.isActionBlockVisible"
            :handle-delete="handleDelete"
            :handle-edit="handleEdit"
            :handleFilter="handleFilter"
            :direction="directionSort"
            :order="orderSort"
            :name="'user'"
          >
          </CustomTable>
          <div class="pagination">
            <Pagination
              :maxPage="users.lastPage"
              :page="page"
              :urlList="'USERS_LIST'"
              :totalItem="users.total"
            />
          </div>
        </div>
        <div class="list__no-have" v-else>No Users found by this parameter.</div>
      </div>
    </ContentContainer>
    <ModalError
      :error-modal="false"
      :handle-close-error-modal="
        () => {
          this.error = {};
        }
      "
    />
    <MainModal v-if="deleteModal" :on-close="handleCloseConfirmModal" :modal-center="true">
      <div class="list__confirm">
        <div class="list__confirm-title">
          Do you want to delete the Users - {{ deleteRow.name }}?
        </div>
        <div class="list__confirm-buttons">
          <div class="list__confirm-button">
            <MainButton buttonWhite @click.native="handleCloseConfirmModal">CANCEL</MainButton>
          </div>
          <div class="list__confirm-button">
            <MainButton @click.native="handleDeleteRow" :button-cancel="true">DELETE</MainButton>
          </div>
        </div>
      </div>
    </MainModal>
    <MainModal v-if="deleteModalSuccess" :on-close="handleCloseSuccessModal">
      <div class="list__success-delete">User {{ deleteRow.name }} successfully deleted.</div>
    </MainModal>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import usersApi from "~/api/user";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import CustomTable from "~/components/molecules/CustomTable.vue";
import Pagination from "~/components/molecules/Pagination.vue";
import MainModal from "~/components/molecules/MainModal.vue";
import ModalError from "~/components/molecules/ModalError.vue";

import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";

export default {
  name: "UsersList",
  metaInfo: {
    title: "Users List",
  },
  data() {
    return {
      isChangeCredit: false,
      developer: null,
      deleteModal: false,
      deleteRow: null,
      deleteModalSuccess: false,
      directionSort: true,
      orderSort: "id",
      formDataToSend: {
        limit: "",
        used: "",
        auto_renewal: false,
        is_active: false,
      },
      tableOptions: {
        deletedKeys: ["null"],
        isActionBlockVisible: true,
        renameColumn: [
          {
            from: "id",
            to: "ID",
          },
          {
            from: "name",
            to: "Name",
          },
          {
            from: "email",
            to: "Email",
          },
          // {
          //   from: "userStatus",
          //   to: "Status",
          // },
        ],
        customOrder: ["id", "name", "email", "null"],
      },
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    CustomTable,
    Pagination,
    MainModal,
    ModalError,
    InputText,
    MainCheckbox,
  },
  mounted() {
    this.getList();
    this.getDeveloper();
  },
  watch: {
    $route(to, from) {
      this.getList();
    },
  },
  computed: {
    ...mapState("users", ["error", "loadingProcess", "users", "usersList"]),
    page() {
      return +this.$route.params.page;
    },
    getMaxPage() {
      return Math.ceil(this.users.pagination.allCount / 20);
    },
    getFirstLink() {
      return ROUTE.DEVELOPER_LIST.replace(":page", 1);
    },
  },
  methods: {
    getList() {
      const data = {
        parameters: {
          developer_id: this.$route.params.developer,
        },
      };
      this.$store.dispatch("users/getUsersList", data);
    },
    handleFilter(column) {
      this.orderSort = column;
      if (this.directionSort === null) {
        this.directionSort = true;
      } else {
        this.directionSort = !this.directionSort;
      }
      this.getList();
    },
    handleDelete(row) {
      this.deleteModal = true;
      this.deleteRow = row;
    },
    handleEdit(row) {
      console.log(row);
      this.$router.push({
        path: ROUTE.USER_EDIT.replace(":id", `${row.id}`).replace(
          ":developer",
          this.$route.params.developer,
        ),
      });
    },
    handleCreate() {
      this.$router.push({
        path: ROUTE.USER_CREATE.replace(":developer", this.$route.params.developer),
      });
    },
    handleCloseConfirmModal() {
      this.deleteModal = false;
    },
    handleDeleteRow() {
      const url = `/${this.deleteRow.id}`;
      this.$store.commit("users/setLoading", true);
      usersApi
        .deleteUser(url)
        .then((res) => {
          this.$store.commit("users/setLoading", false);
          if (res.success) {
            this.getList();
            this.handleCloseConfirmModal();
            this.deleteModalSuccess = true;
          }
        })
        .catch((error) => {
          this.$store.commit("users/setLoading", false);
          console.log(error);
        });
    },
    handleDetailPage(row) {
      this.$router.push({
        path: ROUTE.USER_DETAIL.replace(":id", `${row.id}`),
      });
    },
    handleCloseSuccessModal() {
      this.deleteModalSuccess = false;
    },
    closeModal() {
      this.error = null;
    },
    getDeveloper() {
      const url = `/${this.$route.params.developer}`;
      try {
        usersApi.getItemDeveloper(url).then((res) => {
          this.$store.commit("users/setError", {});
          this.$store.commit("users/setValidationErrors", {});
          const data = res.data;
          this.developer = data;

          this.formDataToSend.auto_renewal = data.credits.autoRenewal;
          this.formDataToSend.is_active = data.credits.isActive;
          this.formDataToSend.limit = data.credits.limit;
          this.formDataToSend.used = data.credits.used;
        });
      } catch (error) {
        console.log(error);
      }
    },
    handleOpenChangeCredits() {
      this.isChangeCredit = true;
    },
    handleSubmitChangeCredits() {
      console.log("hello");
      const url = `/${this.developer.credits.id}`;
      try {
        usersApi
          .editCredit(url, this.formDataToSend)
          .then((res) => {
            if (res.success) {
              this.isChangeCredit = false;
              this.getDeveloper();
            }
          })
          .catch((error) => {
            const { data } = error.response;
          })
          .finally(() => {});
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.custom-table__header {
  grid-template-columns: 0.4fr 1fr 1fr 1fr !important;
}
/deep/.custom-table__row {
  grid-template-columns: 0.4fr 1fr 1fr 1fr !important;
}
.credits {
  padding-bottom: 30px;

  &__button {
    padding-bottom: 20px;
    display: flex;

    /deep/ .button-main {
      width: fit-content;
    }
  }

  &__title {
    font-size: 28px;
    font-weight: 700;
    padding-bottom: 20px;
  }

  &__list-item {
    font-size: 20px;
    padding-bottom: 10px;
  }
}
</style>
