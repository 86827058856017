<template>
  <div>
    <div :class="['checkbox-input', disabled && 'checkbox-input--disabled']">
      <div class="checkbox-input__checkbox-container">
        <input
          type="checkbox"
          :id="`${name}-${id}`"
          v-model="setValue[id]"
          :value="setValue[id]"
          :disabled="disabled"
        />
        <div
          @click="setValue[id] = !setValue[id]"
          :class="['checkbox-input__checkbox', setValue[id] && 'checkbox-input__checkbox--checked']"
        >
          <svg
            v-if="setValue[id]"
            width="14"
            height="10"
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.506348 5.36595L4.95812 9.82622L13.5063 1.28644L12.2791 0.0761719L4.95812 7.38867L1.71659 4.14716L0.506348 5.36595Z"
              fill="#fff"
            />
          </svg>
        </div>
      </div>
      <label :for="`${name}-${id}`">{{ label }}<slot /></label>
    </div>
    <div v-if="errorMessages.length">
      <p v-for="error in errorMessages" :key="error.id">
        {{ error }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "InputCheckboxMain",
  props: {
    id: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.checkbox-input {
  display: flex;
  input {
    display: none;
  }
  &__checkbox {
    position: relative;
    top: -1px;
    width: 18px;
    height: 18px;
    border: 1px solid #131313;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
    &--checked {
      background: $g-color-white;
    }
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      path {
        fill: $mainColor;
      }
    }
  }
  &--disabled {
    label {
      color: red;
      margin-left: 20px;
    }
  }
  label {
    margin-left: 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
}
</style>
